<template>
<div class="advert">
    <div class="top">
      <picture>
        <img alt="advert-picture" :src=baseImage>
      </picture>
      <div class="infoadvert">
        <p :class="{size: getDruh }">{{ getDruh }}</p>
      </div>
    </div>
    <div class="bottom">
      <h3>{{ item.nazev }}</h3>
      <p class="price">{{ getPrice }}</p>
    </div>
    <div class="takers">
        <div class="taker" v-for="taker in item.takers" :key="taker.id">
        <p class="first-name">{{taker.jmeno}}</p>
        <p class="last-name">{{taker.prijmeni}}</p>
        <p class="email">{{taker.email}}</p>
        <p class="phone">+{{taker.telefon}}</p>
        <button class="button white small uk-margin-large-top red btn-email" @click="mailFn(taker.email)">Napsat<br/>e-mail</button>
        <button class="button small uk-margin-large-top red btn-phone" @click="callFn(taker.telefon)">Zavolat</button>
        </div>
        
    </div>
</div>
</template>
<script>
import {mapGetters} from "vuex";
export default{
    name: "takersCard",
  props: ['item'],
  computed:{
    ...mapGetters([
      'getImages'
    ]),
    getDruh(){
      let idDruh = this.item.druh;
      if( idDruh && this.druh[idDruh] )
      {
        return this.druh[idDruh];
      }
      return null;
    },
    getPrice(){
      let price = this.item.cena;
      if( price )
      {
        let idMeny = this.item.mena;
        return this.formatPrice(this.item.cena) + ' ' + this.meny[idMeny];
      }
      return null;
    },
    
  },
  data() {
    return {
      show: false,
      baseImage: null,
      meny:{
        1: "Kč",
        4: "Kč/m2",
        11: "Eur",
        44: "Eur/m2",
      },
      druh: {
        15: 'Rodinný',
        16: 'Vila',
        560: 'Chata',
        188: 'Jiný',
        112: "1+kk",
        113: "1+1",
        122: "2+kk",
        123: "2+1",
        125: "3+kk",
        126: "3+1",
        132: "4+kk",
        133: "4+1",
        135: "5+kk",
        136: "5+1",
        137: "6",
        33: "pro bydlení",
        34: "pro komerční výstavbu",
        31: "zemědělská půda",
        38: "les",
        32: "zahrada",
        35: "rybníky",
        36: "ostatní",
        27: "kanceláře",
        28: "sklady",
        25: "výroba",
        21: "obchodní prostory",
        221: "restaurace",
        222: "ubytování",
        23: "zemědělské objekty",
        224: "jiné",
        17: "činžovní dům",
        41: "garáž",
        410: "garážové stání",
        420: "vinný sklep",
        42: "ostatní"
      }
    }
  },
  async created() {
    let images = await this.getImages(this.item.id);
    if( images ){
      if( images[1] ){
        if (images[1].base){
          this.baseImage = images[1].base;
        }
      }
    }

    if( this.baseImage == null ){
      this.baseImage = require('@/assets/images/inzerat.png');
    }
  },
  methods:{
    formatPrice(price){
        const formatter = new Intl.NumberFormat('cs-CZ', {});
        return formatter.format(price);
    },
    mailFn(mail){
        return window.open(`mailto:<${mail}>`);
    },
    callFn(number){
        return window.open(`tel:<+${number}>`);
    }
  }
  }
</script>

<style scoped lang="less">
.advert {
  position: relative;
  display: block;
  background: #fff;
  overflow: hidden;
  max-width: 38rem;
  width: 100%;
  border-radius: 2rem;
  margin: auto;
  margin-bottom: 2rem;
  padding-bottom:2rem;

  .top {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 38rem;
    widows: 100%;
    height: 14.1rem;

    picture {
      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
      }
    }
    .infoadvert {
        position:absolute;
        top:1.5rem;
        right:1.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .size {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;
        color: #7C7C7B;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0.7rem 3rem;
        border-radius: 5rem;
        margin-top: 0;
        margin-bottom: 0;
        background-color:rgba(255,255,255,0.8)
      }
    }
    .label {
      position: absolute;
      top: 1.7rem;
      right: 1.7rem;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.3rem;
      color: #7C7C7B;
      padding: 0.9rem 4rem 0.9rem 2.1rem;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: fadeout(#fff, 20);
      border-radius: 5rem;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 1.5rem;
        bottom: 0;
        margin: auto;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background: #EFAD3C;
        z-index: 2;
      }

      &.yes {
        &:before {
          background: #2FAC66;
        }
      }
    }
  }

  .bottom {
    position: relative;
    padding: 2rem;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    h3 {
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 2rem;
      line-height: 3rem;
      color: #7C7C7B;
      text-align: left;
      margin-top: 0;
      margin-bottom: 1.1rem;
    }

    .price {
      font-family: 'Poppins';
      font-weight: 700;
      font-size: 2rem;
      line-height: 3rem;
      color: #03A3BD;
      text-align: left;
      margin-top: 0;
      margin-bottom: 1.1rem;
    }

  }
  .takers{
    -webkit-padding-before: 2rem;
            padding-block-start: 2rem;
    padding-inline:5%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap:4rem;
    
    & .taker{
        position:relative;
        width:100%;
        display:-ms-grid;
        display:grid;
        -ms-flex-line-pack: center;
            align-content: center;
        gap:.5rem;
        grid-template-columns: 0fr 1fr 0fr;
            grid-template-areas:
        "jmeno prijmeni btnemail"
        "email email btnemail"
        "phone phone btnphone";
        & p{
         margin:0;
        }
        & button{
            margin-top:0 !important;
        }
        & .first-name{
            -ms-grid-row:1;
            -ms-grid-column:1;
            grid-area:jmeno;
        }
        & .last-name{
            -ms-grid-row:1;
            -ms-grid-column:3;
            grid-area:prijmeni;
        }
        & .first-name,
        & .last-name{
            color:#7C7C7B;
            font-size: 1.667rem;
            font-weight: 600;
        }
        & .email{
            -ms-grid-row:3;
            -ms-grid-column:1;
            -ms-grid-column-span:3;
            grid-area:email;
            
        }
        & .phone{
            -ms-grid-row:5;
            -ms-grid-column:1;
            -ms-grid-column-span:3;
            grid-area:phone;
        }
        & .email,
        & .phone{
            color:#2FAC66;
            font-size: 1.667rem;
            font-weight: 600;
            -webkit-padding-before:.5rem;
                    padding-block-start:.5rem;
        }
        & .btn-email{
            -ms-grid-row:1;
            -ms-grid-row-span:3;
            -ms-grid-column:5;
            grid-area:btnemail;
            -ms-grid-row-align: center;
                align-self: center;
        }
        & .btn-phone{
            -ms-grid-row:5;
            -ms-grid-column:5;
            grid-area:btnphone;
            -ms-grid-row-align: center;
                align-self: center;
        }
        &::before{
            position:absolute;
            content:'';
            width:100%;
            height:1px;
            border-radius: 100px;
            top: -2rem;
            left:0%;
            background-color: #CCCCCC;
        }
        &:first-child{
            &::before{
                top: -3rem;
            }
        }
    }
  }
}

</style>