<template>
<section class="home-content">
    <Takers-list/>
</section>
</template>
<script lang="js">
import TakersList from '../components/takersList.vue';
export default{
    name:'Takers',
    components:{
        TakersList
    }
}
</script>