<template>
  <div class="takers-contents">
    <div class="container">
      <h2>Zájemci</h2>
      <div v-if="takenAdverts.length">
        <TakersCard
          v-for="(advert, index) in takenAdverts"
          :item="advert"
          :index="index"
          :key="advert.id"
          :ref="advert.id"
        ></TakersCard>
      </div>
      <!-- <button @click="getTakers">Získat zájemce</button> -->
    </div>
    <teleport to="body">
      <Modal :show="showSpinner">
        <template #header>
          <h3>{{ spinnerMessage }}</h3>
        </template>
        <template #body>
          <div v-if="!takersLoaded" class="spinner-wrapper">
            <img src="../assets/images/spinner.svg" alt="" class="spinner" />
          </div>
        </template>
        <template #footer>
          <div v-if="!takersLoaded"></div>
          <button v-if="takersLoaded" class="button small uk-margin-large-top red" @click="closeModal">OK</button>
        </template>
      </Modal>
    </teleport>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TakersCard from "./takersCard.vue";
import Modal from "./general/modal.vue";
export default {
  components: {
    TakersCard,
    Modal,
  },
  data() {
    return {
      takenAdverts: [],
      showSpinner: false,
      takersLoaded: false,
      spinnerMessage: "",
      redirect: null,
    };
  },
  computed: {
    ...mapGetters(["countAdverts", "getId", "getSavedTakers"]),
  },
  mounted() {
    if (this.countAdverts(this.getId) === 0) {
      this.spinnerMessage = "Nemáte v aplikaci žádné inzeráty.";
      this.showSpinner = true;
      this.takersLoaded = true;
      this.redirect = "/";
    }
    if (this.countAdverts(this.getId) !== 0) {
      this.spinnerMessage = "Načítáme zájemce o Vaše nemovitosti.";
      this.showSpinner = true;
      this.getTakers();
    }
  },
  methods: {
    displayTakers(adverts) {
      console.log(adverts);
      this.takenAdverts = adverts.map((advert) => {
        if (advert[0].id) {
          return {
            id: advert[0].id,
            remoteId: advert[0].remoteId,
            nazev: advert[0].steps[1].nazev,
            cena: advert[0].steps[2].cena,
            mena: advert[0].steps[2].mena,
            druh: advert[0].steps[1].druh,
            takers: advert[0].zajemci,
          };
        }
      });
      console.log(this.takenAdverts);
    },
    async getTakers() {
      try {
        const { takenAdverts } = await this.$store.dispatch("getTakers");
        // console.log(takenAdverts)
        this.displayTakers(takenAdverts);

        if (!this.takenAdverts.length) {
          this.spinnerMessage = "U Vašich inzerátů prozatím neevidujeme žádné zájemce.";
          this.redirect = "/adverts";
        }
        if (this.takenAdverts.length) {
          this.spinnerMessage = "Hotovo.";
        }
        this.takersLoaded = true;
        // console.log(this.takenAdverts)
      } catch (err) {
        console.log(err);
        const takenAdverts = this.getSavedTakers;
        console.log(takenAdverts);
        this.displayTakers(takenAdverts);
        if (!navigator.onLine) {
          this.spinnerMessage = "Nejste připojeni k internetu";
        } else {
          this.spinnerMessage = "Něco se nepovedlo. Zkuste prosím inzeráty aktualizovat později.";
        }
        this.takersLoaded = true;
      }
    },
    closeModal() {
      this.showSpinner = false;
      this.takersLoaded = false;
      if (this.redirect) {
        this.$router.push(this.redirect);
      }
    },
  },
};
</script>
<style scoped lang="less">
.takers-contents {
  & .container {
    position: relative;
    max-width: 42.8rem;
    height: 93vh;
    overflow: auto;
    margin: auto;
    background: #f2f2f2;
    box-sizing: border-box;
    padding-bottom: 4rem;
  }
}
h2 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 3rem;
  line-height: 4.6rem;
  color: #7c7c7b;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2.2rem;
}
</style>
